import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "./ButtonAction.module.scss";
import classNames from "classnames";

const ButtonActionContext = createContext();
export const useButtonActionContext = () => useContext(ButtonActionContext);

/**
 * @params isActive boolean
 * @params icon string? 
 * @params label string
 * @params children function({close}): ReactNode
 * @params buttonClassNames string?
 * @params modalClassNames string?
 *
 * @returns
 */
export const ButtonAction = ({
  isActive,
  icon,
  label,
  children,
  buttonClassNames = '',
  modalClassNames,
}) => {
  const [open, setOpen] = useState(false);
  const modalRef = useRef();
  const btnRef = useRef();

  /**fix: hook value doenst change in listener event */
  const stateRef = React.useRef(open);
  const setOpenModal = (isOpen) => {
    setOpen(isOpen);
    stateRef.current = isOpen;
  };

  const closeModal = () => {
    setOpenModal(false);
  };

  const toggleModal = () => {
    setOpenModal(!open);
  };

  const handleClick = (e) => {
    if (
      !modalRef?.current ||
      !stateRef.current ||
      btnRef.current.contains(e.target) ||
      // this line for fixing dropdown issue, element not exists when option selected
      !document.contains(e.target)
    ) {
      return;
    }

    if (!modalRef.current.contains(e.target)) {
      closeModal();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return (
    <ButtonActionContext.Provider
      value={{
        close: closeModal,
      }}
    >
      <div className="btn-action-root">
        <button
          ref={btnRef}
          className={classNames(
            "btn-action-container",
            isActive || open ? "active" : "",
            buttonClassNames,
          )}
          onClick={toggleModal}
        >
          {icon && <img src={icon} />}

          {label}
          <div
            className={classNames(
              "btn-action-arrow",
              open ? "lifepal-arrow-up" : "lifepal-arrow-down",
              buttonClassNames.includes('center-text') ? 'center-text-right-arrow' : ''
            )}
          ></div>
        </button>

        {open && (
          <div className={classNames("btn-action-modal",
            modalClassNames ?? '',
          )}
            ref={modalRef}
          >
            {children}
          </div>
        )}
      </div>
    </ButtonActionContext.Provider>
  );
};
